
let step = 0;
let layer = 1;

let graphic = document.getElementById('graphic');
let graphic_inner = document.getElementById('graphic_inner');
console.log(graphic);
let nav = document.getElementById('graphic_nav');
let layer2 = document.getElementById('layer2');

let graphic_nav = document.getElementById('graphic_nav');
let subtitle1 = document.getElementById('graphic_subtitle1');
let subtitle2 = document.getElementById('graphic_subtitle2');

let subtext1 = document.getElementById('graphic_subtext1');
let subtext2 = document.getElementById('graphic_subtext2');

let graphic_modal1 = document.getElementById('graphic_modal1');
let graphic_modal2 = document.getElementById('graphic_modal2');
let graphic_modal3 = document.getElementById('graphic_modal3');

let graphic_modal1_wrap = document.getElementById('graphic_modal1_wrap');
let graphic_modal2_wrap = document.getElementById('graphic_modal2_wrap');
let graphic_modal3_wrap = document.getElementById('graphic_modal3_wrap');

let graphic_modal1_next = document.getElementById('graphic_modal1_next');
let graphic_modal2_next = document.getElementById('graphic_modal2_next');
let graphic_modal3_rewind = document.getElementById('graphic_modal3_rewind');


/* tmp remove modal nav */
graphic_inner.classList.value = '';


graphic_modal1_wrap.addEventListener('click', function handleClick(event) {
  console.log('user clicked modal1_next');
  graphic_modal1.classList.value = 'graphic_modal';
  graphic_inner.classList.value = '';
  graphic.classList.value = 'layer'+layer;
  subtext1.classList.value = 'graphic_subtext_active';
  subtext2.classList.value = '';
  if ( [...document.body.classList].includes('mobile') ) {
    step = 1;
    graphic.classList.add('step'+step,'layer1');
    console.log('processing mobile');
  } else {
    step = 0;
  }
});
graphic_modal2_wrap.addEventListener('click', function handleClick(event) {
  console.log('user clicked modal2_next');
  graphic_modal2.classList.value = 'graphic_modal';
  graphic_inner.classList.value = '';
  graphic.classList.value = 'layer'+layer;
  if ( [...document.body.classList].includes('mobile') ) {
    step = 1;
    graphic.classList.add('step'+step,'layer2');
  } else {
    step = 0;
  }
  layer = 2;
});
graphic_modal3_wrap.addEventListener('click', function handleClick(event) {
  console.log('user clicked modal3_next');
  graphic_modal3.classList.value = 'graphic_modal';
  graphic_modal1.classList.value = 'graphic_modal graphic_modal_active';
  graphic_inner.classList.value = '';
  step = 0;
  layer = 1;
  subtitle1.classList.value = 'graphic_subtitle graphic_subtitle_active';
  subtitle2.classList.value = 'graphic_subtitle';
  subtext1.classList.value = 'graphic_subtext_active';
  subtext2.classList.value = '';
  nav.classList.value = '';
  nav.style.display = 'block';

  layer2.classList.value= '';

});
graphic_nav.addEventListener('click', function handleClick(event) {
    console.log('----------------------');
    console.log('user clicked nav');
    console.log('step: ', step);
    console.log('layer: ', layer);
    graphic.classList.value = '';
    max_step = 4;
    if ( layer == 2 ) {
      max_step = 8;
    }
    if ( layer ==  3 ) {
      nav.style.display = 'none';
    } else {
      nav.style.display = 'block';
    }

    if ( ( step == -1 ) && ( layer == 2 )) {
      console.log("layer2 start detection");
      graphic_modal1.classList.value = 'graphic_modal';
      graphic_modal2.classList.value = 'graphic_modal graphic_modal_active';
      graphic_inner.classList.value = 'modal_active';
      step = 0;
      graphic.classList.add('step'+step,'layer'+layer);
      nav.classList.value = '';
      nav.classList.value = 'graphic_nav2';
    } else if ( ( step == -1 ) && ( layer == 3 )) {
      console.log("layer3 start detection");
      graphic_modal3.classList.value = 'graphic_modal graphic_modal_active ';
      graphic_inner.classList.value = 'modal_active';
      step = 0;
    } else if  ( step == max_step ) {
      console.log("layer all end detection");
      step = -1;
      /* without modal choose step = 0 */
      /* step = 0; */
      graphic.classList.add('step'+step,'layer'+layer);
      nav.classList.value = '';
      nav.classList.value = 'graphic_nav2';
    } else {
      step = step + 1;

      if ( layer == 1 ) {
        subtitle1.classList.value = 'graphic_subtitle graphic_subtitle_active';
        subtitle2.classList.value = 'graphic_subtitle';
        subtext1.classList.value = 'graphic_subtext_active';
        subtext2.classList.value = '';
        nav.classList.value = '';
      } else if ( layer == 2 ) {
        subtitle1.classList.value = 'graphic_subtitle';
        subtitle2.classList.value = 'graphic_subtitle graphic_subtitle_active';
        subtext1.classList.value = '';
        subtext2.classList.value = 'graphic_subtext_active';
        nav.classList.value = 'graphic_nav2';
      } else {
          layer = 1;
          step = -1;
      }
      if ( layer2.classList.contains('layer_active') ) {
        graphic.classList.add('step'+step,'layer'+layer);
        layer2.classList.value= 'layer_active';

      } else {
        graphic.classList.add('step'+step,'layer'+layer);
      }

      let fullfillment_second_line_tspan = document.getElementById('tspan22022');
      let fullfillment_second_line_tspan_alt = document.getElementById('tspan22022_alt');
      if ( ( layer == 2 ) ) {
      /* if ( ( layer == 2 ) && ( ( step == 2 ) || ( step == 6 )) ) { */
        fullfillment_second_line_tspan.style.fill = 'transparent';
        fullfillment_second_line_tspan_alt.style.fill = '#008066';
      } else {
        fullfillment_second_line_tspan.style.fill = '#008066';
        fullfillment_second_line_tspan_alt.style.fill = 'transparent';
      }

      if ( step == max_step ) {

        graphic.classList.add('done','layer'+layer);
        subtitle1.classList.value = '';
        subtitle1.classList.add('graphic_subtitle');
        subtitle2.classList.add('graphic_subtitle','graphic_subtitle_active');
        /*
        subtext1.classList.value = '';
        subtext2.classList.value = 'graphic_subtext_active';
        */
        if ( layer ==  1 ) {
          layer = 2;
          step = -1;
          /* step = 0; */
          layer2.classList.value= 'layer_active';
        } else if ( layer ==  2 ) {
          layer = 3;
          step = -1;
          layer2.classList.value= 'layer_active';
        }
      }

    }
    console.log('end of step, next....');
    console.log('step: ', step);
    console.log('layer: ', layer);
    console.log('--------------');

});


subtitle1.addEventListener('click', function handleClick(event) {
    layer = 1;
    graphic.classList = '';
    graphic.classList.add('layer'+layer);
    nav.classList.value = '';
    nav.classList.value = 'graphic_nav1';
    nav.style.display = 'block';    
    subtitle1.classList.value = 'graphic_subtitle graphic_subtitle_active';
    subtitle2.classList.value = 'graphic_subtitle';
    subtext1.classList.value = 'graphic_subtext_active';
    subtext2.classList.value = '';
    graphic_modal1.classList.value = 'graphic_modal graphic_modal_active';
    graphic_modal2.classList.value = 'graphic_modal';
    graphic_modal3.classList.value = 'graphic_modal';
    graphic_inner.classList.value = 'modal_active';

    layer2.classList.value= '';


    console.log('user clicked title1');
    step = -1;
    step = 0;
    if ( [...document.body.classList].includes('mobile') ) {
      step = 1;
      graphic.classList.add('step'+step,'layer1');
      console.log('processing mobile');
    } else {
      step = 0;
    }    
});

subtitle2.addEventListener('click', function handleClick(event) {
    layer = 2;
    graphic.classList = '';
    graphic.classList.add('layer'+layer);
    nav.classList.value = '';
    nav.classList.value = 'graphic_nav2';
    nav.style.display = 'block';
    subtitle1.classList.value = 'graphic_subtitle';
    subtitle2.classList.value = '';
    subtitle2.classList.value = 'graphic_subtitle graphic_subtitle_active';
    subtext1.classList.value = '';
    subtext2.classList.value = 'graphic_subtext_active';
    graphic_modal1.classList.value = 'graphic_modal';
    graphic_modal2.classList.value = 'graphic_modal graphic_modal_active';
    graphic_modal3.classList.value = 'graphic_modal';
    graphic_inner.classList.value = 'modal_active';
    layer2.classList.value= 'layer_active';

    console.log('user clicked title2');
    step = -1;
    if ( [...document.body.classList].includes('mobile') ) {
      step = 1;
      graphic.classList.add('step'+step,'layer2');
      console.log('processing mobile');
    } else {
      step = 0;
    }
});


/* -------------------- */
/* mobile */
let mobile_query = '(max-width: 450px)'
let tablet_query = '(min-width: 451px) and (max-width: 992px)';
let desktop_query = '(min-width: 993px)';

let mobile = window.matchMedia(mobile_query);
let tablet = window.matchMedia(tablet_query);
let desktop = window.matchMedia(desktop_query);
mediaWidth(mobile);
mediaWidth(tablet);
mediaWidth(desktop);
mobile.addListener(mediaWidth);
tablet.addListener(mediaWidth);
desktop.addListener(mediaWidth);

function mediaWidth(x) {
  console.log('match');
  if (x.matches ) {
    if ( x.media == mobile_query ) {
      document.body.classList.value = 'de mobile';
      graphic.classList.add('step1','layer1');
      
    } else if ( x.media == tablet_query ) {
      document.body.classList.value = 'de tablet';
    } else if ( x.media == desktop_query ) {
      document.body.classList.value = 'de desktop';
    }
  } 
}